import React from "react"
import { Link } from "gatsby"
import ArrowRightIcon from "@heroicons/react/outline/ArrowRightIcon"
import { StaticImage } from "gatsby-plugin-image"

export const Printemps = ({ id, title, description, label, more, to }) => {
  return (
    <div className="container mx-auto">
      <div className="relative px-4 pt-10 pb-10 lg:pb-20 sm:pt-16 sm:px-6 lg:pt-20 lg:px-8">
        <div className="relative grid max-w-6xl mx-auto md:grid-cols-2">
          <div className="relative items-center md:flex">
            <StaticImage
              src={
                "../../../articles/exemples-de-haiku/printemps/illustration.jpg"
              }
              className="rounded-lg h-72 md:h-auto"
              alt={`image de quizz`}
              title={`image de quizz`}
            />
          </div>

          <div className="mt-10 md:mt-0 md:flex md:items-center">
            <div className="md:pl-8 lg:pl-20">
              <p className="font-medium text-green-600 uppercase text-regular">
                {label}
              </p>

              <h2 id={id} className="mt-2 text-3xl font-bold text-gray-800 md:text-4xl">
                {title}
              </h2>

              <p className="mt-8 text-lg text-gray-700">{description}</p>

              <div className="flex items-center mt-8 text-lg font-medium text-green-600 group">
                <Link to={to}>{more}</Link>
                <ArrowRightIcon className="w-5 ml-4 transition-all duration-300 ease-in-out transform -5 group-hover:translate-x-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
