import React from "react"

export const H1: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="px-4">
    <h1 id={id} className="max-w-2xl mx-auto mt-12 text-4xl font-bold">
      {children}
    </h1>
  </div>
)

export const H2: React.FC<{ id?: string; center?: boolean }> = props => (
  <div className="px-4">
    <h2
      id={props.id}
      className={`block max-w-2xl mx-auto mt-12 text-4xl font-extrabold tracking-tight text-gray-900 ${
        props.center ? "text-center" : ""
      }`}
    >
      {props.children}
    </h2>
  </div>
)

export const H3: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="px-4">
    <h3 id={id} className="max-w-2xl mx-auto mt-12 text-2xl font-bold">
      {children}
    </h3>
  </div>
)

export const H4: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="px-4">
    <h4 id={id} className="max-w-2xl mx-auto mt-12 text-3xl text-gray-600">
      {children}
    </h4>
  </div>
)

export const H5: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="px-4">
    <h5 id={id} className="max-w-2xl mx-auto mt-12 text-2xl font-bold">
      {children}
    </h5>
  </div>
)

export const H6: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="px-4">
    <h6 id={id} className="max-w-2xl mx-auto mt-12 text-2xl">
      {children}
    </h6>
  </div>
)

export const Green: React.FC = ({ children }) => (
  <span className="text-green-600">{children}</span>
)
