import React from "react"

export const Ol: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="px-4">
    <ol
      className="max-w-2xl pl-6 mx-auto mt-6 prose-lg text-gray-700 list-decimal prose-green"
      id={id}
    >
      {children}
    </ol>
  </div>
)
