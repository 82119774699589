import React from "react"

export const TableOfContent: React.FC<{
  title: string
  anchors: Array<{ id: string; text: string; depth: number }>
}> = ({ title, anchors }) => (
  <>
    <div className="px-4">
      <div className="max-w-2xl mx-auto mt-12 text-2xl font-bold">{title}</div>
    </div>
    <div className="px-4">
      <ol className="max-w-2xl pl-4 mx-auto mt-6 prose-lg text-gray-700 md:pl-0 prose-green">
        {anchors.map(({ id, text, depth }) => (
          <li key={id}>
            <a
              className={`font-medium hover:underline ${
                depth === 1 ? "pl-4" : depth === 2 ? "pl-8" : ""
              }`}
              href={`#${id}`}
            >
              {text}
            </a>
          </li>
        ))}
      </ol>
    </div>
  </>
)
