import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Haikus } from "./Haikus"

type Props = {
  title: string
}

export const RandomHaiku: React.FC<Props> = props => {
  const data = useStaticQuery(graphql`
    query GetRandomHaikuQuery {
      allRandomHaiku {
        nodes {
          haiku {
            content
            id
            theme
          }
          author {
            id
            name
          }
        }
      }
    }
  `)

  return (
    <Haikus
      title={props.title}
      haikus={data.allRandomHaiku.nodes.map(({ haiku, author }) => ({
        content: haiku.content,
        category: haiku.theme,
        author: {
          fullName: author.name,
          url: `/recueils/auteurs/${author.id}/`,
        },
      }))}
    />
  )
}
