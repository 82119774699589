import React from "react"

export const Ul: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className="px-4">
    <ul
      className="max-w-2xl pl-4 mx-auto mt-6 prose-lg text-gray-700 list-disc md:pl-0 prose-green"
      id={id}
    >
      {children}
    </ul>
  </div>
)

export const Li: React.FC<{ id?: string }> = ({ children, id }) => (
  <li id={id}>{children}</li>
)
