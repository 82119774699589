import { Link } from "gatsby"
import React from "react"
import { As } from "../As/As"

type Props = {
  title?: string
  haiku: string
  noMarginTop?: boolean
  category?: string
  author?: {
    fullName: string
    url?: string
  }
}

export const Haiku: React.FC<Props> = props => (
  <section
    className={`relative py-20 ${
      props.noMarginTop ? "" : "mt-8"
    } overflow-hidden bg-gray-50`}
  >
    <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="relative">
        {props.title && (
          <p className="block text-sm font-semibold tracking-wide text-center text-gray-400 uppercase sm:text-base lg:text-sm xl:text-base">
            {props.title}
          </p>
        )}

        <blockquote className="flex justify-center mt-10">
          <div>
            <div
              className="max-w-3xl mx-auto font-serif text-2xl italic font-medium leading-9 text-gray-900"
              dangerouslySetInnerHTML={{ __html: props.haiku }}
            />
            {props.author && (
              <Link
                to={props.author.url}
                className={`hover:text-green-500 hover:underline block pl-2 mt-10 font-serif text-base italic font-medium text-gray-400 border-l-2 border-gray-200`}
              >
                {props.author.fullName}
              </Link>
            )}
            
            {props.category && (
              <span className="inline-flex items-center px-2 py-1 mt-8 text-sm font-medium text-gray-700 capitalize bg-gray-100 rounded-md">
                {props.category}
              </span>
            )}
          </div>
        </blockquote>
      </div>
    </div>
  </section>
)
