import React from "react"

export const Pattern: React.FC<{ direction: "left" | "right" }> = ({
  direction,
}) => (
  <div className={`relative`}>
    <div className="hidden xl:block xl:absolute xl:inset-y-0 xl:h-full xl:w-full">
      <div className="relative" aria-hidden="true">
        <svg
          className={`absolute top-8 ${
            direction === "right" ? "right-0" : "left-0"
          } transform ${
            direction === "right" ? "" : ""
          }`}
          width="300"
          height="384"
          fill="none"
          viewBox="0 0 300 384"
        >
          <defs>
            <pattern
              id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="384"
            fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
          />
        </svg>
      </div>
    </div>
  </div>
)
