import React from "react"
import AdSense from "react-adsense"

export const Ads: React.FC<{ type?: "base" }> = ({ type }) => {
  return (
    <div className="px-4 py-4">
      <div className="max-w-screen-md mx-auto">
        <AdSense.Google
          client="ca-pub-1533832539203264"
          slot="5327083376"
          style={{ display: "block" }}
          layout="in-article"
          format="fluid"
        />
      </div>
    </div>
  )
}
