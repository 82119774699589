import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export function Tipeee() {
  return (
    <div className="relative py-8 bg-white md:py-16 lg:px-8">
      <div className="max-w-6xl mx-auto bg-red-tipeee lg:bg-transparent">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
            <div
              className="absolute inset-x-0 bg-white h-1/2 lg:hidden"
              aria-hidden="true"
            />

            <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
              <div className="hidden lg:block">
                <StaticImage
                  className="object-cover object-center shadow-2xl bg-gray-50 rounded-3xl"
                  src="../../assets/logo/tipeee-square.svg"
                  alt="tipeee"
                />
              </div>
              <div className="lg:hidden">
                <StaticImage
                  className="object-cover object-center shadow-2xl bg-gray-50 rounded-3xl"
                  src="../../assets/logo/tipeee.svg"
                  alt="tipeee"
                />
              </div>
            </div>
          </div>

          <div className="relative bg-red-tipeee lg:col-start-3 lg:row-start-1 lg:col-span-10 rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
            <div className="relative max-w-md px-4 py-12 mx-auto space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
              <div className="text-3xl font-bold text-white">
                Soutenez-nous sur Tipeee
              </div>
              <p className="text-lg text-white opacity-90">
                Si vous appréciez le Temple du Haïku et que vous souhaitez nous
                aider dans notre mission et rendre plus accessible et simple
                d'accès l'art de l’haïku pour tout le monde, alors le meilleur
                moyen de nous aider est de nous soutenir sur Tipeee.
              </p>
              <a
                className="block w-full px-5 py-3 text-base font-medium text-center text-red-700 bg-white border border-transparent rounded-md shadow-md hover:bg-gray-50 sm:inline-block sm:w-auto"
                href="https://fr.tipeee.com/temple-du-haiku"
              >
                Le Temple du Haïku sur Tipeee
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
